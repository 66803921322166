.box-title {
  &-center {
    align-items: center;
    display: flex;
    flex-direction: column;

    h2,
    h3,
    h4 {
      text-align: center;
    }
  }

  .flag-text {
    margin-bottom: 14px;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 8px;
  }

  p {
    color: #565656;
    font-weight: 500;
  }
}