h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2F2F2F;
  font-family: $secondary-font;
  font-weight: 600;
}

h1:not(.no-config),
.title-h1 {
  font-size: 6.2rem;
  line-height: 6.7rem;
}

h2:not(.no-config),
.title-h2 {
  font-size: 3.8rem;
  line-height: 4.4rem;
}

h3:not(.no-config),
.title-h3 {
  font-size: 3rem;
  line-height: 4rem;
}

h4:not(.no-config),
.title-h4 {
  font-size: 2.2rem;
  line-height: 2.8rem;
}

@include responsive(1279) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 5.8rem;
    line-height: 6rem;
  }
}

@include responsive(991) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 5.5rem;
    line-height: 5.8rem;
  }
}

@include responsive(767) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 4.8rem;
    line-height: 5.2rem;
  }

  h2:not(.no-config),
  .title-h2 {
    font-size: 3.6rem;
    line-height: 4.2rem;
  }

  h3:not(.no-config),
  .title-h3 {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
}

@include responsive(599) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 4rem;
    line-height: 4.4rem;
  }

  h2:not(.no-config),
  .title-h2 {
    font-size: 3.4rem;
    line-height: 4.2rem;
  }
}