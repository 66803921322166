.loader {
  aspect-ratio: 1;
  animation: loaderBtn 1s infinite linear;
  border: 3px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  width: 20px;

  &-dark {
    border-top-color: #07489f;
    border-left-color: #07489f;
    border-bottom-color: #07489f;
  }
}