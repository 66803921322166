input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
select,
textarea {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  transition: 0.3s;
  font-size: inherit;
  line-height: inherit;

  &::placeholder {
    color: #565656;
    opacity: 0.8;
  }

  &:not(.no-focus):focus {
    border-color: rgba(7, 72, 159, 0.533) !important;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(204,219,239, 0.6);
    box-shadow: 0 0 0 0.25rem rgba(204,219,239, 0.6);
  }
  
  &:not(.no-focus):focus:-internal-autofill-selected,
  &:not(.no-focus):focus:-internal-autofill-previewed {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset,
      0 0 0 0.25rem rgba(204,219,239, 0.6);
    box-shadow: 0 0 0px 1000px #fff inset, 0 0 0 0.25rem rgba(204,219,239, 0.6);
  }

  &::-webkit-input-placeholder {
    color: #202020;
  }
  
  &::-moz-placeholder {
    color: #202020;
  }
  
  &:-ms-input-placeholder {
    color: #202020;
  }
  
  &::-ms-input-placeholder {
    color: #202020;
  }
  
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
  }

  &.invalid {
    border-color: rgba(189, 49, 49, 0.906) !important;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(236, 96, 96, 0.2);
    box-shadow: 0 0 0 0.25rem rgba(236, 96, 96, 0.2);
  }
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
select {
  align-items: center;
  display: inline-flex;
  height: 52px;
  padding: 11px 16px 12px;
}

.box-input {
  max-width: 100%;

  & + .box-input {
    margin-top: 14px;
  }

  &.grid-2 {
    grid-column: 2 span;
  }
}

.range {
  &-box {
    margin-inline: auto;
    margin-top: 37px;
    position: relative;
    width: 100%;
    
    input[type=range] {
      -webkit-appearance: none;
      background-color: transparent !important;
      height: 21px;
      width: 100%;
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
      animate: 0.2s;
      background-color: #d5d5d5;
      border-radius: 25px;
      cursor: pointer;
      height: 6px;
      width: 100%;
    }

    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
      border: 6px solid #07489F;
      position: relative;
      top: -7px;
      cursor: pointer;
      width: 20px;
      z-index: 99;
    }
  }

  &-value {
    position: absolute;
    top: -50%;

    &-line {
      background-color: #07489F;
      border-radius: 25px;
      bottom: 14px;
      height: 6px;
      left: 0;
      pointer-events: none;
      position: absolute;
      touch-action: none;
      z-index: 1;
      width: 7%;
    }

    span {
      background-color: #FFF;
      border: 2px solid #07489F;
      border-radius: 5px;
      height: 24px;
      line-height: 2rem;
      text-align: center;
      color: #07489F;
      font-size: 1.4rem;
      font-weight: 700;
      display: inline-flex;
      justify-content: center;
      left: 50%;
      padding-inline: 8px;
      position: absolute;
      text-align: center;
      top: -20px;
      transform: translate(-50%, 0);
      min-width: max-content;
      z-index: 1;
      width: max-content;

      &:before {
        content: '';
        position: absolute;
        height: 0;
        border-top: 7px solid #07489F;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        margin-top: -0px;
        width: 0;
      }
  
      span {
        margin-left: 3px;
      }
    }
  }
}

label {
  display: block;
  line-height: 2rem;
}

@include responsive(599) {
  .box-input {
    &.grid-2 {
      grid-column: 1;
    }
  }
}