.hero {
  background-color: #004AAD;
  overflow: hidden;

  &-left {
    max-width: 480px;
    padding-block: 128px 169px;
    position: relative;
  }

  &-title,
  &-description {
    color: #fff;
  }

  &-title {
    margin-bottom: 16px;
    position: relative;

    &-icons {
      display: flex;
      position: absolute;
      top: 14px;
      right: 8px;

      img {
        & + img {
          margin-left: -10px;
        }
      }
    }
  }

  &-description {
    font-size: 1.8rem;
    line-height: 3rem;
    margin-bottom: 32px;
    max-width: 452px;
  }

  &-right {
    pointer-events: none;
    position: relative;
    touch-action: none;
    z-index:  1;

    &-decoration {
      bottom: 132px;
      pointer-events: none;
      position: absolute;
      touch-action: none;
      left: -83px;
    }

    &-img {
      pointer-events: none;
      position: relative;
      touch-action: none;
      
      &.desktop {
        right: 50px;
      }

      &.mobile {
        display: none;
      }
    }
  }

  &-decoration {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    position: absolute;
    right: -554px;
    touch-action: none;
    top: 0;

    img {
      & + img {
        margin-top: 32px;
      }
    }
  }

  &-result {
    display: flex;

    &-list {
      display: flex;
    }

    &-left {
      p {
        color: #fff;
        font-size: 13px;
        font-weight: 300;
        line-height: 18px;
        margin-bottom: 8px;
        opacity: 0.7;
        text-transform: uppercase;
      }
    }

    &-right {
      align-items: flex-end;
      display: flex;
      margin-bottom: -74px;
    }
    
    &-item {
      background: linear-gradient(113deg, rgba(255, 255, 255, 0.24) 20.7%, rgba(255, 255, 255, 0.01) 106.73%);
      border: 1px solid #FFF;
      border-radius: 6px;
      height: 187px;
      padding: 8px 11px 20px;
      width: 119px;
      z-index: 1;

      & + .hero-result-item {
        margin-left: 12px;
      }

      &-top {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 6.5px;

        small {
          background-color: #fff;
          border-radius: 2px;
          opacity: 0.5;
          height: 2.5px;
          width: 10px;
        }

        div {
          align-items: center;
          display: flex;
        }

        svg {
          opacity: 0.5;

          & + svg {
            margin-left: 2.5px;
          }
        }
      }

      &-img {
        margin-bottom: 8px;
        
        img {
          border-radius: 3px;
        }
      }

      &-content {
        div {
          background-color: #FFF;
          border-radius: 2px;
          border: 1px solid #B8B8B8;
          opacity: 0.2;

          &:nth-child(1) {
            height: 26px;
            width: 100%;
          }

          &:nth-child(2) {
            height: 16px;
            width: 59px;
          }

          & + div {
            margin-top: 5px;
          }
        }
      }
    }

    &-check {
      align-items: center;
      background-color: #fff;
      border-radius: 2px;
      display: flex;
      height: 52px;
      justify-content: center;
      margin-bottom: -24px;
      width: 52px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.numbers {
  margin: 112px 0 80px;

  &-content {
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    padding-bottom: 67.51px;
  }

  &-stamp {
    align-items: center;
    display: flex;
    height: 134.04px;
    justify-content: center;
    margin-right: 5px;
    position: relative;
    width: 121px;

    &-star {
      background: linear-gradient(168deg, #1C539C -4.05%, #2B6DC5 91.07%);
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 52px;
      justify-content: center;
      left: calc(50%);
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 52px;
    }

    &-letters {
      animation: stampRotate 14s infinite linear;
    }
  }

  &-list {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 904px;
    width: 100%;
  }

  &-item {
    width: 100%;

    &:nth-child(1) {
      max-width: 203px;
    }

    &:nth-child(2) {
      max-width: 214px;
    }

    &:nth-child(3) {
      max-width: 280px;
    }

    strong {
      color: #0648A1;
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 2.7rem;
      line-height: 3.2rem;
      display: block;
      margin-bottom: 10px;

      span {
        font-size: 3.6rem;
        line-height: 3.2rem;
      }
    }

    p {
      color: #565656;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2.2rem;
    }
  }
}

.categories {
  margin-bottom: 160px;

  &-left {
    max-width: 315px;
    width: 100%;
  }

  &-right {
    max-width: 854px;
    width: 100%;
  }

  &-box {
    &-top {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &-nav {
      align-items: flex-end;
      display: flex;

      ul {
        align-items: center;
        display: flex;
        max-width: 630px;
        padding-bottom: 8px;

        &::-webkit-scrollbar {
          height: 3px;
          width: 3px;
        }
        
        &::-webkit--track {
          background-color: #eee;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: #cccc;
        }

        li {
          & + li {
            margin-left: 20px;
          }
          
          a {
            background-color: rgba(111, 111, 111, 0.13);
            border-radius: 4px;
            color: #4A4A4A;
            font-weight: 500;
            height: 42px;
            padding: 8px 20px;
            text-transform: uppercase;
            transition: 0.3s background-color;

            @include hover() {
              &:hover {
                background-color: rgba(111,111,111,.15);
              }
            }
  
            &.active {
              color: #004AAD;
              background: rgba(0, 74, 173, 0.13);
              touch-action: none;
              pointer-events: none;
            }
          }
        }

      }
    }

    &-text {
      align-items: center;
      display: flex;
      opacity: 0.6;

      svg {
        margin-right: 10px;
      }
    }

    &-progress {
      &-line {
        background-color: rgba(109,109,109,0.2);
        border-radius: 2px;
        height: 3px;
        margin-bottom: 7px;
        overflow: hidden;
        position: relative;
        width: 119px;

        div {
          background-color: #004AAD;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 20%;
        }
      }

      &-pagination {
        color: #979797;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 2.5rem;
        margin-left: auto;
        max-width: max-content;
      }

      .hero-slider-progress-line {
        &.loading {
          div {
            transition: 6.3s width;
            width: 100%;
          }
        }
      }
    }

    &-item {
      border: 1px solid #D1D1D1;
      border-radius: 4px;
      display: none;
      padding: 28px 22px;

      &.active {
        animation: toRight 0.4s forwards ease-in-out;
        display: flex;
      }

      &-img {
        height: 298px;
        max-width: 100%;
        width: 439px;

        img {
          border-radius: 4px;
          height: 100%;
          object-fit: cover;
          object-position: center;
          width: 100%;
        }
      }

      &-content {
        margin-left: 24px;
        max-width: 347px;
        padding-top: 10px;

        .link-arrow {
          margin-top: 24px;
        }

        h3 {
          font-size: 2.2rem;
          line-height: 2.8rem;
          margin-bottom: 12px;
        }

        p {
          color: #565656;
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
  }
}

.about {
  &-box {
    background-color: #CCDBEF;
    display: flex;
    border-radius: 20px;
    margin: 220px auto 160px;
    max-width: 1008px;
    padding: 60px 25px;
    position: relative;
  }

  &-img {
    position: relative;
    width: 359px;
    z-index: 1;

    &-logo {
      left: 95%;
      transform: translateX(-50%);
    }

    img {
      left: 0;
      position: absolute;
      bottom: -60px;

      &.mobile {
        display: none;
      }
    }
  }

  &-content {
    max-width: 453px;
    position: relative;
    z-index: 1;

    .btn {
      margin-top: 24px;
    }

    h2 {
      color: #01214A;
      margin-bottom: 12px;
    }
  }

  &-decoration {
    border-radius: 20px;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    &-text {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.2;
      
      img {
        opacity: 0.5;

        & + img {
          margin-top: 14px;
        }
      }
    }

    &-logo {
      bottom: 29.86px;
      opacity: 0.2;
      position: absolute;
      right: -67px;

      img {
        opacity: 0.45;
      }
    }
  }
}

.how-work {
  margin-bottom: 160px;

  &-top {
    max-width: 454px;
    margin: 0 auto 44px;
  }

  &-list {
    display: flex;
    justify-content: space-between;
  }

  &-item {
    max-width: 280px;

    & + .how-work-item {
      margin-left: 32px;
    }

    &-icon {
      align-items: center;
      background-color: #07489F;
      border-radius: 4px;
      display: flex;
      height: 50px;
      justify-content: center;
      margin-bottom: 26px;
      width: 50px;
    }

    &-title {
      color: #2F2F2F;
      display: block;
      font-family: $secondary-font;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.3rem;
      margin-bottom: 10px;
    }


    &-description {
      color: #565656;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.8rem;
    }
  }
}

.benefits {
  background-color: #01367C;
  height: 464px;
  margin-bottom: 160px;
  overflow: hidden;
  padding-top: 103px;
  position: relative;

  &-left {
    margin-top: 50px;
    max-width: 356px;
    position: relative;
    width: 100%;
    z-index: 1;

    h2,
    p {
      color: #fff;
    }

    h2 {
      margin-bottom: 12px;
    }
  }

  &-img {
    margin-inline: 26px 46px;
    max-width: 437px;
    position: relative;
    width: 100%;

    &-main {
      position: relative;
      z-index: 1;
    }

    &-logo {
      bottom: -11px;
      height: 535px;
      left: -147px;
      min-width: 695px;
      position: absolute;
    }
  }

  &-right {
    margin-top: 30px;
    position: relative;
    z-index: 1;

    .benefits {
      &-item {
        align-items: center;
        color: #fff;
        display: flex;

        & + .benefits-item {
          margin-top: 32px;
        }

        &-img {
          align-items: center;
          background-color: rgba(173, 199, 234, 0.16);
          border-radius: 50%;
          display: flex;
          height: 38px;
          justify-content: center;
          margin-right: 8px;
          min-width: 38px;

          div {
            align-items: center;
            background-color: #004AAD;
            border: 4px solid rgba(173, 199, 234, 0.20);


            border-radius: 50%;
            display: flex;
            height: 30px;
            justify-content: center;
            position: relative;
            width: 30px;
          }
        }
      }
    }
  }

  &-decoration {
    &-left,
    &-right {
      position: absolute;
      touch-action: none;
      pointer-events: none;
    }

    &-left {
      left: 0;
      top: 0;
    }

    &-right {
      bottom: 0;
      right: 0;
    }
  }

  .container {
    display: flex;
  }
}

.faq {
  margin-bottom: 180px;

  &-left {
    max-width: 334px;
  }

  &-right {
    max-width: 800px;
    width: 100%;
  }

  &-item {
    &:not(:last-child) {
      border-bottom: 1px solid #B3B3B3;
      margin-bottom: 28px;
    }

    &-title {
      background-color: transparent;
      display: flex;
      justify-content: space-between;
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 10px;
      width: 100%;

      &.active {
        img {
          transform: rotate(45deg);
        }

        & + .faq-item-description {
          max-height: 4000px;
          padding-bottom: 28px;

          div {
            animation: fade 0.3s forwards;
            animation-delay: 0.3s;
          }
        }
      }

      div {
        display: flex;
        margin-right: 10px;
      }

      span {
        color: #004AAD;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 2.3rem;
        margin-right: 10px;
      }

      strong {
        color: #2F2F2F;
        display: inline-block;
        line-height: 2.6rem;
        margin-right: 10px;
        text-align: left;
      }

      img {
        margin-top: 4px;
        transition: 0.3s transform;
      }
    }

    &-description {
      max-height: 0;
      max-width: 760px;
      padding-bottom: 18px;
      padding-left: 36px;
      transition: 0.3s;
      width: 100%;

      div {
        opacity: 0;
        margin-bottom: 0;
      }

      p {
        color: #565656;
      }
    }
  }


  .container {
    display: flex;
    justify-content: space-between;
  }
}

.newsletter {
  background-color: #01367C;
  padding-block: 88px;

  &-content {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-inline: auto;
    max-width: 1112px;
  }

  &-text {
    max-width: 540px;

    h2 {
      color: #fff;
    }
  }

  &-form {
    &-img {
      margin-left: 6px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .btn-loader {
      height: 49px;
      justify-content: center;
      padding: 10.5px 24px 10.5px;
      position: absolute;
      right: 7px;

      &.loading {
        span {
          transform: translateY(-203%);
        }
      }

      &-box {
        height: 21px;

        .loader {
          border-width: 2.8px;
        }
      }
    }

    > div {
      background-color: #fff;
      border-radius: 4px;
      height: 60px;
      padding: 5.5px 7px 5.5px 8px;
      position: relative;
      top: 5.5px;
      width: 428px;
    }

    label {
      color: #fff;
      display: block;
      font-weight: 300;
      line-height: 2.2rem;
      margin-bottom: 4px;
    }

    input {
      height: 100%;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      outline: none;
      padding-left: calc(31px + 4px);
      padding-right: 170px;
      width: 100%;
    }
  }
}

@include responsive(1279) {
  .hero {
    &-left {
      padding-block: 108px 82px;
    }

    &-right {
      &-img {
        &.desktop {
          right: -40px;
        }
      }

      &-decoration {
        bottom: 62px;
        left: 0px;
      }
    }

    &-title {
      &-icons {
        right: 32px;
        top: 9px;
      }
    }

    &-result {
      &-item {
        height: 164px;
        padding: 8px 8px 20px;
        width: 106px;
      }

      &-line {
        max-width: 140px;
      }

      &-check {
        margin-bottom: 26px;
      }
    }
  }

  .numbers {
    &-list {
      max-width: 780px;
    }
  }

  .categories {
    &-left {
      margin-right: 32px;
      max-width: 300px;
    }

    &-right {
      max-width: 600px;
    }

    &-box {
      &-nav {
        ul {
          li {
            & + li {
              margin-left: 10px;
            }

            a {
              font-size: 1.5rem;
            }
          }
        }
      }

      &-text {
        display: none;
      }

      &-item {
        &-content {
          max-width: 290px;
        }
      }
    }
  }

  .how-work {
    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 60px 40px;
      max-width: 730px;
      margin-inline: auto;
    }

    &-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      
      & + .how-work-item {
        margin-left: 0;
      }

      h2,
      p {
        text-align: center;
      }
    }
  }

  .benefits {
    height: auto;

    &-left {
      grid-column: 2 span;
      margin: 0 auto 22px;
      max-width: 410px;

      h2,
      p {
        text-align: center;
      }
    }

    &-img {
      margin-inline: auto -30px;
    }

    &-right {
      margin-left: 60px;
      margin-top: 44px;

      .benefits {
        &-item {
          & + .benefits-item {
            margin-top: 20px;
          }
        }
      }
    }

    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .newsletter {
    &-content {
      align-items: center;
      flex-direction: column;
    }

    &-text {
      margin-bottom: 24px;

      h2 {
        text-align: center;
      }
    }
  }
}

@include responsive(991) {
  .hero {
    &-left {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-inline: auto;
      padding-block: 48px 100px;

      .hero-title {
        &-icons {
          display: none;
        }
      }

      h1,
      p {
        text-align: center;
      }
    }

    &-right {
      left: 20px;
      margin-inline: auto;
      max-width: 370px;

      &-img {
        right: 0;

        &.desktop {
          display: none;
        }
        
        &.mobile {
          display: block;
        }
      }

      &-decoration {
        bottom: 40px;
        left: -105px;
      }
    }

    &-result {
      &-check {
        height: 42px;
        margin-bottom: 26px;
        width: 42px;

        img {
          width: 28px;
        }
      }
    }

    .container {
      flex-direction: column-reverse;
    }
  }

  .numbers {
    &-content {
      align-items: center;
      flex-direction: column;
    }

    &-stamp {
      margin-right: 0;
      margin-bottom: 12px;
    }

    &-list {
      flex-wrap: wrap;
      max-width: 480px;
    }

    &-item {
      & + .numbers-item {
        margin-left: 12px;
      }

      &:nth-child(3) {
        margin-left: 0;
        margin-top: 32px;
        max-width: 100%;
        
        p {
          br {
            display: none;
          }
        }
      }
    }
  }

  .categories {
    &-left {
      margin-bottom: 42px;
      max-width: 100%;

      .box-title {
        align-items: center;
        display: flex;
        flex-direction: column;

        h2,
        p {
          text-align: center;
        }

        h2 {
          br {
            display: none;
          }
        }
      }
    }

    &-right {
      max-width: 100%;
      position: relative;
    }

    &-box {
      &-top {
        align-items: center;
        flex-direction: column;
      }

      &-progress {
        margin-top: 16px;
        position: absolute;
        bottom: -50px;
        right: 0;

        &-pagination {
          margin-bottom: 2px;
        }
      }

      &-item {
        flex-direction: column;

        &-img {
          height: 290px;
          margin-bottom: 20px;
          min-width: unset;
          max-width: 100%;
          width: 100%;

          img {
            object-position: top;
          }
        }

        &-content {
          margin-inline: auto;
          max-width: 100%;

          h3 {
            margin-bottom: 6px;
          }
        }
      }
    }

    .container {
      flex-direction: column;
    }
  }

  .about {
    &-box {
      align-items: center;
      flex-direction: column;
      max-width: 572px;
    }

    &-img {
      img {
        bottom: 0;
        height: 340px;
        margin-bottom: 40px;
        margin-inline: auto;
        margin-top: -120px;
        position: inherit;

        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
        }
      }
    }

    &-content {
      .box-title {
        align-items: center;
        display: flex;
        flex-direction: column;

        h2,
        p {
          text-align: center;
        }
      }
    }
  }

  .faq {
    &-left {
      margin-bottom: 44px;
      max-width: 440px;

      .box-title {
        align-items: center;
        display: flex;
        flex-direction: column;

        h2,
        p {
          text-align: center;
        }
      }
    }

    .container {
      align-items: center;
      flex-direction: column;
    }
  }
}

@include responsive(767) {
  .hero {
    &-left {
      p {
        font-size: 1.6rem;
        line-height: 2.6rem;
      }
    }

    &-right {
      &-img {
        &.mobile {
          height: 390px;
        }
      }
    }
  }

  .benefits {
    &-left {
      grid-column: 1;
      margin: 0 auto 44px;
      order: 1;
    }

    &-img {
      margin-inline: auto;
      margin-top: 20px;
      max-width: 380px;
      order: 3;

      &-logo {
        left: 50%;
        min-width: 500px;
        transform: translateX(-50%);
      }
    }

    &-right {
      margin: 0 auto;
      order: 2;
    }

    .container {
      grid-template-columns: 1fr;
    }
  }
}

@include responsive(599) {
  .hero {
    &-left {
      padding-block: 8px 80px;
    }

    &-right {
      left: 0;

      &-img {
        &.mobile {
          height: 310px;
        }
      }

      &-decoration {
        bottom: 60px;
        left: -5px;
      }

      p {
        display: none;
        max-width: 150px;
        position: absolute;
        top: -44px;
      }
    }

    &-decoration {
      max-width: 420px;
      right: -250px;
    }

    &-result {
      &-item {
        height: 128px;
        padding: 8px 8px 20px;
        width: 84px;

        &:nth-child(1) {
          display: none;
        }

        &-content {
          div {
            &:nth-child(1) {
              height: 22px;
            }
        
            &:nth-child(2) {
              height: 13px;
              width: 40px;
            }
          }
        }

      }
    }
  }

  .numbers {
    &-list {
      flex-direction: column;
    }

    &-stamp {
      margin-bottom: 22px;
    }

    &-item {
      max-width: 100% !important;

      & + .numbers-item {
        margin-left: 0;
        margin-top: 42px;
      }

      strong,
      p {
        text-align: center;
      }

      p {
        br {
          display: none;
        }
      }

      strong {
        font-size: 2.4rem;
        line-height: 2.8rem;

        span {
          font-size: 3.2rem;
          line-height: 3rem;
        }
      }

    }
  }

  .categories {
    &-box {
      &-nav {
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
      }
    }
  }

  .how-work {
    &-list {
      grid-template-columns: 1fr;
      max-width: max-content;
    }
  }

  .newsletter {
    padding-block: 68px;

    &-form {
      max-width: 100%;

      &-img {
        top: 23px;
        transform: unset;
      }

      .btn-loader {
        justify-content: center;
        margin-top: 5px;
        position: initial;
        width: 100%;

        &.loading {
          span {
            transform: translateY(-208%);
          }
        }
      }

      > div {
        height: auto;
        padding: 8px 16px 12px;
        max-width: 100%;
      }

      input {
        height: 52px;
        padding-inline: 31px 22px;
      }
    }
  }
}