.flag-text {
  padding: 6px 18px;
  border-radius: 16px;
  border: 1px solid #004AAD;
  color: #004AAD;
  display: inline-block;
  font-family: $secondary-font;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  text-transform: uppercase;
}