@keyframes fade {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}

@keyframes stampRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loaderBtn {
  to {
    transform: rotate(360deg);
  }
}

@keyframes toRight {
  from {
    opacity: 0;
    transform: translate3d(-50px,0,0);
 }
  
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes toUp {
  from {
    opacity: 0;
    transform: translate3d(0,30px,0);
  }
  
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}