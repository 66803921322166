.btn {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  color: #0648A1;
  display: inline-flex;
  font-family: $secondary-font;
  font-size: 1.6rem;
  font-weight: 600;
  height: 60px;
  letter-spacing: -0.1px;
  line-height: 2.2rem;
  padding: 17px 33px 16px;
  text-transform: uppercase;
  transition: 0.3s;

  &-accent {
    background-color: #07489F;
    color: #fff;
    font-weight: 500;
    
    &-dark {
      background-color: #01367C;
      color: #fff;
      font-weight: 500;
    }
    
    svg {
      path {
        stroke: #fff;
      }
    }
  }

  &-hover {
    &-arrow {
      @include hover() {
        &:hover {
          svg {
            left: 2.5px;
          }
        }
      }
    }
  }

  &-loader {
    &.loading {
      pointer-events: none;
      touch-action: none;

      span {
        transform: translateY(-204%);
      }
    }

    &-box {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 21px;
      min-width: calc(100% + 2.5px);
      overflow: hidden;
      position: relative;

      span {
        display: block;
        margin-inline: auto;
        transform: translateY(0);
        transition: 0.3s transform;

        & + span {
          margin-top: 20px;
        }
      }
    }
  }

  &-text-center {
    justify-content: center;
  }

  &-whatsapp {
    align-items: center; 
    background: linear-gradient(204deg, #01367C.65%, #0648A1.54%);
    border-radius: 50%;
    bottom: 26px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.246)!important;
    display: flex;
    height: 50px;
    justify-content: center;
    position: fixed; 
    right: 26px;
    transition: 0.3s !important;
    width: 50px;
    z-index: 9;

    @include hover() {
      &:hover {
        background-color: #07489F !important;
      }
    }
  
    &:not(.aos-animate) {
      opacity: 0;
    }
  
    &:not(.active) {
      opacity: 0 !important;
    }
    
    &.active,
    &.active.aos-animate {
      opacity: 1;
    }
  
    svg {
      left: 0.5px;
      position: relative;
    }
  }

  svg {
    left: 0;
    margin-left: 10px;
    position: relative;
    transition: 0.3s;

    path {
      transition: 0.3s ease-out;
    }
  }

  span {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }
}