.header {
  background-color: #fff;
  display: flex;
  height: 76px;

  &-nav {
    ul {
      align-items: center;
      display: flex;

      li {
        & + li {
          margin-left: 48px;
        }

        &.mobile {
          display: none;
        }

        &:not(.header-btn-whats) {
          a {
            color: #535353;
            letter-spacing: 0.2px;

            @include hover() {
              &:hover {
                color: #004AAD;
              }
            }
          }
        }

        a {
          font-size: 1.5rem;
          font-weight: 500;
          font-family: $secondary-font;
          line-height: 2rem;
          text-transform: uppercase;
        }
      }
    }
  }

  &-btn-mobile {
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    display: none;
    flex-direction: column;
    height: 48px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 99;
    width: 48px;

    &.active {
      span {
        background-color: #fff;

        &:nth-child(1) {
          transform: rotate(-225deg);
        }
        
        &:nth-child(2) {
          display: none;
          transform: translateX(150%);
        }
        
        &:nth-child(3) {
          transform: rotate(45deg);
          margin-top: -2.8px;
        }
      }
    }

    span {
      background-color: #004AAD;
      border-radius: 2px;
      display: block;
      height: 2.8px;
      transition: .3s;
      width: 28px;

      & + span {
        margin-top: 6px;
      }

      svg {
        display: block;
      }
    }
  }

  &-btn-whats {
    a {
      align-items: center;
      background-color: #004AAD;
      border: 1px solid transparent;
      border-radius: 4px;
      color: #fff;
      display: inline-flex;
      font-family: $secondary-font;
      height: 44px;
      padding: 12px 16px;
      transition: 0.3s;

      @include hover() {
        &:hover {
          background-color: transparent;
          border-color: #004AAD;
          color: #004AAD;

          svg {
            path {
              fill: #004AAD;
            }
          }
        }
      }

      span {
        margin-bottom: -2.5px;
      }

      svg {
        margin-left: 8px;

        path {
          transition: 0.3s fill;
        }
      }
    }
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@include responsive(991) {
  .header {
    // position: absolute;
    // width: 100%;
    // z-index: 99;
    // background: transparent;

    &-overlay {
      background-color: rgba(0,0,0,0.9);
      display: block;
      height: 100vh;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      visibility: hidden;
      top: 0;
      touch-action: none;
      transition: 0.3s ease-in-out;
      width: 100%;
      z-index: 99;
      
      &.active {
        opacity: 1;
        visibility: initial;
        pointer-events: initial;
        touch-action: initial;
      }
    }

    &-logo {
      img {
        max-width: 140px;
      }
    }

    &-nav {
      background-color: #004AAD;
      height: 100vh;
      left: -120%;
      position: fixed;
      top: 0;
      transition: 0.3s left;
      width: 320px;
      z-index: 99;

      &.active {
        left: 0;
      }

      &-logo {
        margin-block: 6px 10px;
        padding-left: 20px;
      }

      ul {
        align-items: flex-start;
        flex-direction: column;
        padding-top: 16px;
        max-height: calc(100vh - 30px);
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
          height: 3px;
          width: 3px;
        }
        
        &::-webkit--track {
          background-color: #eee;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: #cccc;
        }

        li {
          width: 100%;

          & + li {
            margin-left: 0;
            margin-top: 24px;
          }

          &:not(.header-btn-whats) {
            a {
              color: #fff;
              display: block;
              padding: 4px 20px;
              width: 100%;

              @include hover() {
                &:hover {
                  color: #CCDBEF;
                }
              }
            }
          }

          &.mobile {
            display: block;
          }
        }
      }
    }

    &-btn-mobile {
      display: flex;
    }
  
    &-btn-whats {
      a {
        background-color: #fff;
        display: flex !important;
        height: 50px;
        justify-content: space-between;
        margin-left: 20px;
        max-width: calc(100% - 40px);

        @include hover() {
          &:hover {
            background-color: #CCDBEF;
            border-color: #CCDBEF;
          }
        }

        span {
          color: #004AAD;
        }
  
        svg {
          path {
            fill: #004AAD;
          }
        }
      }
    }
  }
}

@include responsive(599) {
  .header {
    &-logo {
      img {
        max-width: 120px;
      }
    }

    &-nav {
      width: 260px;
    }
  }
}