.link-arrow {
  align-items: center;
  color: #0648A1;
  display: inline-flex;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.3s color;

  @include hover() {
    &:hover {
      svg {
        left: 2.5px;
      }
    }
  }

  svg {
    left: 0;
    margin-left: 10px;
    position: relative;
    transition: 0.3s left;
  }
}