.header-simulator {
  background-color: #0648A1;
  backdrop-filter: unset;
  position: relative;

  &-back {
    align-items: center;
    color: #fff;
    display: flex;
    font-weight: 500;

    &:hover {
      svg {
        margin-right: 12.5px;
      }
    }

    svg {
      margin-right: 10px;
      transition: 0.3s;
      
      path {
        transition: 0.3s;
      }
    }
  }
}