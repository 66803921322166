.footer {
  background-color: #01214B;
  padding-block: 46px 32px;

  &-main {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-nav {
      ul {
        align-items: center;
        display: flex;

        li {
          & + li {
            margin-left: 68px;
          }

          a {
            color: #fff;
            font-size: 1.5rem;
            font-weight: 500;
            letter-spacing: 0.2px;
            line-height: 2rem;
            text-transform: uppercase;
            transition: 0.3s color;

            @include hover() {
              &:hover {
                color: #ccdbef;
              }
            }
          }
        }
      }
    }
  }

  &-bottom {
    align-items: center;
    border-top: 1px solid rgba(217,217,217, 0.1);
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    padding-top: 32px;

    &-text {
      color: #fff;
      opacity: 0.5;
      font-weight: 300;
    }

    &-author {
      align-items: center;
      color: #fff;
      display: flex;
      transition: 0.3s;

      @include hover() {
        &:hover {
          color: #ccdbef;
  
          img {
            opacity: 0.8;
          }
        }
      }

      img {
        margin-left: 6px;
        transition: 0.3s opacity;
      }
    }
  }

  &-networks {
    ul {
      align-items: center;
      display: flex;

      li {
        & + li {
          margin-left: 20px;
        }
        
        a {
          align-items: center;
          background-color: #CCDBEF;
          border-radius: 4px;
          display: flex;
          height: 40px;
          justify-content: center;
          width: 40px;
        }
      }
    }
  }
}

@include responsive(991) {
  .footer {
    &-main {
      flex-direction: column;

      &-nav {
        margin-block: 32px;

        ul {
          li {
            & + li {
              margin-left: 32px;
            }
          }
        }
      }
    }

    &-bottom {
      flex-direction: column;

      &-text {
        margin-bottom: 4px;
      }

      &-text,
      a {
        text-align: center;
      }
    }
  }
}

@include responsive(599) {
  .footer {
    &-main {
      &-nav {
        ul {
          flex-direction: column;

          li {
            & + li {
              margin-left: 0;
              margin-top: 14px;
            }
          }
        }
      }
    }
  }
}